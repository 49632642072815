import { Injectable, EventEmitter } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class EnvironmentService {
	public selectedSchedulePeriodChanged$: EventEmitter<number>;
	public selectedSchedulePeriodChangedExternally$: EventEmitter<any>;
	public selectedSchedulePeriod;
	public refreshShiftCountOverview$: EventEmitter<any>;
	public refreshTotalOpenAllocationRequestCount$: EventEmitter<any>;
	public refreshTotalOpenTrainingChangesCount$: EventEmitter<any>;
	
	public hoveredElementChanged$: EventEmitter<any>;
	public hoveredElement;

  	constructor() { 
		this.selectedSchedulePeriodChanged$ = new EventEmitter();
		this.selectedSchedulePeriodChangedExternally$ = new EventEmitter();
		this.hoveredElementChanged$ = new EventEmitter();
		this.refreshShiftCountOverview$ = new EventEmitter();
		this.refreshTotalOpenAllocationRequestCount$ = new EventEmitter();
		this.refreshTotalOpenTrainingChangesCount$ = new EventEmitter();
	}

	translateForIndustry(term) {
		var industryId = this.getUser().customer.industry_id;

		if (!industryId) {
			industryId = 1;
		}

		var dictionary = {
			1: 
				{
					'candidate': 'arts',
					'Candidate': 'Arts',
					'candidates': 'artsen',
					'Candidates': 'Artsen',
					'candidate_type': 'artstype',
					'Candidate_type': 'Artstype',
					'candidate_types': 'artstypen',
					'Candidate_types': 'Artstypen'
				},
			2: 
				{
					'candidate': 'docent',
					'Candidate': 'Docent',
					'candidates': 'docenten',
					'Candidates': 'Docenten',
					'candidate_type': 'docenttype',
					'Candidate_type': 'Docenttype',
					'candidate_types': 'docenttypen',
					'Candidate_types': 'Docenttypen'
				},
			3: 
				{
					'candidate': 'fotograaf',
					'Candidate': 'Fotograaf',
					'candidates': 'fotografen',
					'Candidates': 'Fotografen',
					'candidate_type': 'fotograaftype',
					'Candidate_type': 'Fotograaftype',
					'candidate_types': 'fotograaftypen',
					'Candidate_types': 'Fotograaftypen'
				}
		};

		return dictionary[industryId][term];
	}
	
	refreshTotalOpenAllocationRequestCount() {
		this.refreshTotalOpenAllocationRequestCount$.emit();
	}

	refreshTotalOpenTrainingChangesCount() {
		this.refreshTotalOpenTrainingChangesCount$.emit();
	}
	
	refreshShiftCountOverview(changedShifts) {
		this.refreshShiftCountOverview$.emit({ schedulePeriodId: this.selectedSchedulePeriod.id, changedShifts: changedShifts });
	}

	// environmentService.refreshTrainingPeriodDifferencesOverview = function() {
	//     $rootScope.$broadcast('refreshTrainingPeriodDifferencesOverview');
	// }

	setUser(id, candidateId, lastname, isAdmin, isTrainingAdmin, isTrainer, isSuperAdmin, customer, schedulePeriodIds, periodicCandidates, location, regionalCandidateId, isDayScheduleNotAccessible, hasOnlyAccessToTrainingPeriodAccumulationTypeId, hasOnlyAccessToOwnTraineesAndLocation, hasOnlyAccessToTaskDisplayGroups, isAllocationRequestAdmin, isDayScheduleAlsoAccessible) {
		localStorage.setItem('user', JSON.stringify({ 
			id: id, 
			candidateId: candidateId, 
			lastname: lastname, 
			isAdmin: isAdmin,
			isTrainer: isTrainer,
			isTrainingAdmin: isTrainingAdmin, 
			isSuperAdmin: isSuperAdmin,
			isTrainee: periodicCandidates.filter(function(x) { return x.candidate_type && x.candidate_type.is_trainee; }).length > 0,
			customer: customer, 
			schedulePeriodIds: schedulePeriodIds,
			showReportsMenu: (isAdmin && customer.reports.length > 0) || (candidateId && customer.reports.filter(function(x) { return x.is_published; }).length > 0),
			periodicCandidates: periodicCandidates,
			location_id: location ? location.id : null,
			location: location,
			regionalCandidateId: regionalCandidateId,
			isDayScheduleNotAccessible: isDayScheduleNotAccessible,
			hasOnlyAccessToTrainingPeriodAccumulationTypeId: hasOnlyAccessToTrainingPeriodAccumulationTypeId,
			hasOnlyAccessToOwnTraineesAndLocation: hasOnlyAccessToOwnTraineesAndLocation,
			hasOnlyAccessToTaskDisplayGroups: hasOnlyAccessToTaskDisplayGroups,
			isAllocationRequestAdmin: isAllocationRequestAdmin,
			isDayScheduleAlsoAccessible: isDayScheduleAlsoAccessible
		}));
	}

	getUser() {
		return JSON.parse(localStorage.getItem('user'));
	}

	deleteUser() {
		localStorage.removeItem('user');
	}

	setSchedulePeriodSelection(selectedSchedulePeriod) {
		this.selectedSchedulePeriod = selectedSchedulePeriod;
		this.selectedSchedulePeriodChanged$.emit(selectedSchedulePeriod);
	}

	setSchedulePeriodSelectionExternally(selectedSchedulePeriod) {
		this.selectedSchedulePeriodChangedExternally$.emit(selectedSchedulePeriod);
	}

	getSchedulePeriodSelection() {
		return this.selectedSchedulePeriod;
	}

	setHoveredElement(hoveredElement) {
		this.hoveredElement = hoveredElement;
		this.hoveredElementChanged$.emit(hoveredElement);
	}

	getHoveredElement() {
		return this.hoveredElement;
	}

	reset() {
		this.hoveredElement = null;
		this.selectedSchedulePeriod = null;
	 	//localStorage.removeItem('user');
	}
}
