import { Component, OnInit, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import templateString from './daySchedule.component.html'
import { forkJoin, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';
import { DataService } from './../../data.service';
import { EnvironmentService } from './../../environment.service';
import { UserHiddenTaskDisplayGroup } from './../../models/userHiddenTaskDisplayGroup.model';
import { BsModalService } from 'ngx-bootstrap/modal';
import { AssignTaskModalComponent } from './../assignTaskModal/assignTaskModal.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Candidate } from 'site/app/models/candidate.model';
import { TruncatePipe } from 'site/app/pipes/truncate.pipe';
import { HttpClient } from '@angular/common/http';

@Component({ 
	template: templateString,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayScheduleComponent implements OnInit, AfterViewInit, OnDestroy {
	public isVisible;
	public selectedSchedulePeriod;
	public selection;
	public autoPlanSelection;
	public isInitializing;
	public isLoading;
	public isMultiSelect;
	public selectedGanttTasksForMultiSelect = [];
	public columnWidth = 28;
	public fromDate;
	public toDate;
	public numberOfDays;
	public dates;
	public autoPlanWeekNumbers;
	public shiftAccumulationTypes;
	public dataKpiRows = [];
	public data = [];
	public tasks;
	public numberOfTaskDisplayGroups;
	public timespans;
	public dateHeaderRows = [];
	public user;
	public taskDisplayGroupCombinations = [];
	public isAutoPlanning = false;
	public optimizationGroups;
	public horizontalScrollReceiver1;
	public verticalScrollReceiver1;
	public verticalScrollReceiver2;
	public shiftAssignments;
	public currentHovered = null;
	public tempIndex = 0;
	public isPublishedOrPartlyPublished: boolean;
	public isPublishedPartly: any;
	public destroyed$;
	public mouseover;
	public mouseout;
	public dayScheduleTabs: any;
	public selectedDayScheduleTabId: any = null;
	public workingDayTimeSlots: any;
	public kpis: any;
	public viewTypeId: any;
	public dayForViewType: any;
	public weekForViewType: any;
	public days: any;
	public weekNumbers: any;
	public innerWidth: any;
	public showMobileView: any;
	public restrictedAdminTaskDisplayGroupIds: any = [];
	public restrictedAdminTaskTypeIds: any = [];
	public hasCountsDisplayGroup = false;
	public countsDisplayGroupMultiplier = 0;
	public taskDisplayGroupVisibility = {};
	public selfRosteringShiftIds: any = [];

	constructor(
		private http: HttpClient,
		private dataService: DataService,
		private environmentService: EnvironmentService,
		private bsModalService: BsModalService,
		private changeDetectorRef: ChangeDetectorRef,
		private router: Router,
		private toastr: ToastrService,
		private truncatePipe: TruncatePipe,
		private route: ActivatedRoute
	) { 
		var self = this;
		this.destroyed$ = new Subject<boolean>();
		
		// use 1 event listener for all tooltips and show tooltip based on event target, for better performance
		// see also https://github.com/valor-software/ngx-bootstrap/issues/1536#issuecomment-539576500
		// create event listener according to https://javascript.info/mousemove-mouseover-mouseout-mouseenter-mouseleave
        this.mouseover = function(event: Event){
            // before entering a new element, the mouse always leaves the previous one
			// if currentElem is set, we didn't leave the previous <td>,
			// that's a mouseover inside it, ignore the event
			if (self.currentHovered != null) return;

			let target = self.closestByClass(event.target, 'for-tooltip');

			// we moved not into a <td> - ignore
		  	if (!target) return;

		  	self.currentHovered = target;
			self.tempIndex += 1;

			let boundingRectangle = target.getBoundingClientRect();

			self.environmentService.setHoveredElement({
				text: self.getTooltipText(self.currentHovered),
				bottom: boundingRectangle.bottom,
				left: boundingRectangle.left,
				right: boundingRectangle.right
			});
        }

		document.addEventListener('mouseover', this.mouseover);
		
		this.mouseout = function(event: Event) {
			// if we're outside of any <td> now, then ignore the event
			// that's probably a move inside the table, but out of <td>,
			// e.g. from <tr> to another <tr>
			if (!self.currentHovered) return;

			// we're leaving the element – where to? Maybe to a descendant?
		  	let relatedTarget = (<any> event).relatedTarget;

		  	while (relatedTarget) {
				// go up the parent chain and check – if we're still inside currentElem
				// then that's an internal transition – ignore it
			    if (relatedTarget == self.currentHovered) return;
				
				relatedTarget = relatedTarget.parentNode;
			}

			// we left the <td>. really.
			self.currentHovered = null;
			self.environmentService.setHoveredElement(null);
		}

		document.addEventListener('mouseout', this.mouseout);
	}
	
	detectChanges() {
		if (!this.destroyed$.isStopped) {
			this.changeDetectorRef.detectChanges();
		}
	}

	closestByClass(el, tooltipClassName) {
	    // Traverse the DOM up with a while loop
	    while (!el.classList || !el.classList.contains(tooltipClassName)) {
	        // Increment the loop to the parent node
	        el = el.parentNode;
	        if (!el) {
	            return null;
	        }
	    }
	    // At this point, the while loop has stopped and `el` represents the element that has
	    // the class you specified in the second parameter of the function `clazz`

	    // Then return the matched element
	    return el;
	}

	setStartEndDate() {
		var weekNumbers = [];
		var currentDate = moment(this.selectedSchedulePeriod.start_date);
		while (moment(currentDate) < moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day')) {
			weekNumbers.push(currentDate.week());
			currentDate = moment(currentDate).add(1, 'week');
		}
		this.weekNumbers = weekNumbers;

		switch(this.viewTypeId) {
			case 1:
				if (!this.dayForViewType) {

					var today = moment(moment().format("YYYY-MM-DD"));
					var startOfSchedulePeriod = moment(this.selectedSchedulePeriod.start_date);
					var endOfSchedulePeriod = moment(this.selectedSchedulePeriod.end_date);
	
					this.dayForViewType = (today > startOfSchedulePeriod && today < endOfSchedulePeriod ? today : startOfSchedulePeriod).format("YYYY-MM-DD");
				}
	
				this.fromDate = moment(this.dayForViewType);
				this.toDate = moment(this.dayForViewType);

				var days = [];
				var currentDate = moment(this.selectedSchedulePeriod.start_date);
				while (moment(currentDate) < moment(this.selectedSchedulePeriod.end_date)) {
					days.push({ title: currentDate.format("DD-MM"), value: currentDate.format("YYYY-MM-DD") });
					currentDate = moment(currentDate).add(1, 'day');
				}
				this.days = days;

				break;
			case 2:
				if (!this.weekForViewType) {

					var today = moment(moment().format("YYYY-MM-DD"));
					var startOfSchedulePeriod = moment(this.selectedSchedulePeriod.start_date);
					var endOfSchedulePeriod = moment(this.selectedSchedulePeriod.end_date);
	
					this.route.queryParams.subscribe(
						params => {
							if (params["start_date"]) {
								this.weekForViewType = moment(params["start_date"]).week();
							} else {
								this.weekForViewType = (today > startOfSchedulePeriod && today < endOfSchedulePeriod ? today : startOfSchedulePeriod).week();
							}
						}
					);
				}
	
				var weekOfStartDate = moment(this.selectedSchedulePeriod.start_date).week();

				if (this.weekForViewType < weekOfStartDate) {
					this.fromDate = moment(this.selectedSchedulePeriod.start_date).add(1, 'year').week(this.weekForViewType).day("Monday").add(1, 'day').format("YYYY-MM-DD");
				} else {
					this.fromDate = moment(this.selectedSchedulePeriod.start_date).week(this.weekForViewType).day("Monday").add(1, 'day').format("YYYY-MM-DD");
				}
				
				this.toDate = moment(this.fromDate).add(6, 'days');

				break;
			case 3:
				this.fromDate = this.selectedSchedulePeriod.start_date;
				this.toDate = moment(this.selectedSchedulePeriod.end_date).subtract(1, 'day');	

				break;
		}

		this.numberOfDays = this.toDate.diff(this.fromDate, 'days') + 1;
		this.dates = new Array(this.numberOfDays);

		var monthColumns = [];
		var weekColumns = [];
		var dayOfWeekColumns = [];
		var dayColumns = [];

		var currentDate = moment(this.fromDate);
		var index = 0;

		var tempWeekNumber = currentDate.isoWeek();
		var tempWeekIndex = 0;

		var tempMonthAndYear = currentDate.format("MMMM YYYY");
		var tempMonthIndex = 0;

		this.autoPlanWeekNumbers = [];

		while (currentDate <= this.toDate) {
			var currentMonthAndYear = currentDate.format("MMMM YYYY");
			var currentWeekNumber = currentDate.isoWeek();

			if (currentMonthAndYear != tempMonthAndYear) {
				monthColumns.push({ title:  tempMonthAndYear, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index - tempMonthIndex });

				tempMonthAndYear = currentMonthAndYear;
				tempMonthIndex = index;
			}

			if (currentWeekNumber != tempWeekNumber) {
				weekColumns.push({ title: tempWeekNumber, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index - tempWeekIndex });

				tempWeekNumber = currentWeekNumber;
				tempWeekIndex = index;
			}

			if (currentDate.isSame(this.toDate)) {
				monthColumns.push({ title: currentMonthAndYear, offsetInNumberOfCells: tempMonthIndex, widthInNumberOfCells: index + 1 - tempMonthIndex })
				weekColumns.push({ title: currentWeekNumber, offsetInNumberOfCells: tempWeekIndex, widthInNumberOfCells: index + 1 - tempWeekIndex })
			}

			var dayNames = ["zo", "ma", "di", "wo", "do", "vr", "za"];
			dayOfWeekColumns.push({ title: dayNames[currentDate.day()], offsetInNumberOfCells: index, widthInNumberOfCells: 1, isWeekendDay: currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7 });
			dayColumns.push({ title: (((this.viewTypeId == 2 && this.showMobileView) || this.viewTypeId == 3) ? "" : dayNames[currentDate.day()] + " ") + currentDate.date(), offsetInNumberOfCells: index, widthInNumberOfCells: 1, isWeekendDay: currentDate.isoWeekday() === 6 || currentDate.isoWeekday() === 7 });

			if (this.autoPlanWeekNumbers.indexOf(currentWeekNumber) < 0) {
				this.autoPlanWeekNumbers.push(currentWeekNumber);
			}

			currentDate = moment(currentDate).add(1, 'day');
			index++;
		}

		if ((this.viewTypeId == 2 && this.showMobileView) || (this.viewTypeId == 3 && this.user.customer.id == 19)) {
			this.dateHeaderRows = [monthColumns, weekColumns, dayOfWeekColumns, dayColumns];
		} else {
			this.dateHeaderRows = [monthColumns, weekColumns, dayColumns];
		}
	}

	datesForWeek(weekNumber) {
		var weekOfStartDate = moment(this.selectedSchedulePeriod.start_date).week();
		var firstDayOfWeek;

		if (weekNumber < weekOfStartDate) {
			firstDayOfWeek = moment(this.selectedSchedulePeriod.start_date).add(1, 'year').week(weekNumber).day("Monday").add(1, 'day');
		} else {
			firstDayOfWeek = moment(this.selectedSchedulePeriod.start_date).week(weekNumber).day("Monday").add(1, 'day');
		}

		var lastDayOfWeek = moment(firstDayOfWeek).add(6, 'days'); 
		var months = ["jan", "feb", "mrt", "apr", "mei", "jun", "jul", "aug", "sep", "okt", "nov", "dec"]

		return firstDayOfWeek.date() + " " + months[firstDayOfWeek.month()] + " t/m " + lastDayOfWeek.date() + " " + months[lastDayOfWeek.month()];

		//return .format("D ") + " t/m " + moment().day("Sunday").week(weekNumber).format("MM D")
	}

	refreshSpecialEventsAndTimespans(data) {
		var self = this;
		
		// temporary object otherwise not all timespans (weekends and public holidays) are rendered...
		var timespansTemp = [];
		this.timespans = [];

		var currentDate = moment(this.fromDate);

		var ganttTimespanClass = this.user.customer.id == 19 ? "gantt-timespan-dark" : "gantt-timespan-light";

		if (currentDate.day() === 0) {
			timespansTemp.push({
			  from: moment(currentDate),
			  to: moment(currentDate).add(1, 'day'),
			  classes: ganttTimespanClass
			});
		}

		currentDate = currentDate.day(6);

		while (currentDate < this.toDate) {
			timespansTemp.push({
			  from: currentDate,
			  to: moment(currentDate).add(2, 'days'),
			  classes: ganttTimespanClass
			});

		 	currentDate = moment(currentDate).add(1, 'week');
		}

		if (currentDate.day() === 6 && currentDate.isSame(this.toDate)) {
			timespansTemp.push({
			  from: moment(currentDate),
			  to: moment(currentDate).add(1, 'day'),
			  classes: ganttTimespanClass
			});
		}

		// highlight today
		var today = moment().startOf('day');

		if (today >= moment(this.selectedSchedulePeriod.start_date) && today < moment(this.selectedSchedulePeriod.end_date)) {
			timespansTemp.push({
				from: today,
				to: moment(today).add(1, 'day'),
				classes: "gantt-timespan-today"
			});
		}
	
		this.dataKpiRows.filter(function(x) { return x.id == "special" })[0].tasks = data.map(function(x) { return self.dataService.createTaskFromSpecialEvent(x) });

		data.filter(function(x) { return x.is_public_holiday; }).forEach(function(x) {
			timespansTemp.push({
				from: moment(x.start_date),
		  		to: moment(x.end_date),
				classes: "timespanSpecialEventPublicHoliday"
			});
		});

		this.timespans = timespansTemp;
	}

	refreshShiftAccumulationTypeCounts(data) {
		var self = this;

		if (this.workingDayTimeSlots.length == 0 || (this.workingDayTimeSlots.length == 1 && this.workingDayTimeSlots[0].id == 0)) {
			Object.keys(data[0]).forEach(function(shiftAccumulationTypeId) {
				self.dataKpiRows.filter(function(x) { return x.id == ("shiftAccumulationType_" + shiftAccumulationTypeId.toString()) })[0].tasks =
					data[0][shiftAccumulationTypeId].map(function(x) { return self.dataService.createShiftAccumulationTypeCount(x) });
			});
		} else {
			this.workingDayTimeSlots.forEach(function(timeSlot) {
				Object.keys(data[timeSlot.id]).forEach(function(shiftAccumulationTypeId) {
					self.dataKpiRows.filter(function(x) { return x.id == ("shiftAccumulationType_" + shiftAccumulationTypeId.toString() + "_" + timeSlot.id) })[0].tasks =
						data[timeSlot.id][shiftAccumulationTypeId].map(function(x) { return self.dataService.createShiftAccumulationTypeCount(x) });
				});
			});
		}
	}
	
	refreshUnassignedCandidateCounts(data) {
		var self = this;
		if (this.workingDayTimeSlots.length == 0 || (this.workingDayTimeSlots.length == 1 && this.workingDayTimeSlots[0].id == 0)) {
			this.dataKpiRows.filter(function(x) { return x.id == "unassignedCandidateCount" })[0].tasks = data[0].map(function(x) { return self.dataService.createUnassignedCandidateCount(x) });
		} else {
			this.workingDayTimeSlots.forEach(function(timeSlot) {
				self.dataKpiRows.filter(function(x) { return x.id == ("unassignedCandidateCount" + timeSlot.id) })[0].tasks = data[timeSlot.id].map(function(x) { return self.dataService.createUnassignedCandidateCount(x) });	
			});
		}
	}
	
	refreshUnassignedShiftCounts(data) {
		var self = this;
		this.dataKpiRows.filter(function(x) { return x.id == "unassignedShiftCount" })[0].tasks = data.map(function(x) { return self.dataService.createUnassignedShiftCount(x) });
	}

	refreshTasks() {
		var self = this;
		
		return this.http.get('shifts/for_schedule',
			{ 
				params: {
					schedule_period_id: this.selectedSchedulePeriod.id,
					day_schedule_tab_id: this.selectedDayScheduleTabId
				}
			}
		)
		.pipe(takeUntil(this.destroyed$))
		.subscribe( data => {
			var shifts = data;

			this.tasks.forEach(function(task) {
				var indexOfGanttRow = self.data.map(function(x) { return x.id }).indexOf(task.id);

				if (indexOfGanttRow >= 0) {
					var ganttRow = self.data[indexOfGanttRow];

					var maxRowIndex = shifts[task.id] ? Math.max(...shifts[task.id].map(function(x) { return x.row_index; })) : 0;

					if (maxRowIndex > 1) {
						for (var i = 2; i <= maxRowIndex; i++) {
							var newRow = {
								baseName: "",
								belongsToTaskDisplayGroupId: ganttRow.belongsToTaskDisplayGroupId,
								classes: null,
								id: task.id.toString() + "_" + i.toString(),
								isDisplayGroup: false,
								isGroupedRow: true,
								isLastOfGroupedTasks: ganttRow.isLastOfGroupedTasks && i == maxRowIndex,
								name: "",
								tasks: shifts[task.id].filter(function(x) { return x.row_index == i; }).map(function(x) { return self.dataService.createScheduleTaskFromShift(x, task, self.viewTypeId, self.showMobileView, self.fromDate, self.toDate) }),
								hasHigherRowIndex: i != maxRowIndex
							};
							
							self.data.splice(indexOfGanttRow + i - 1, 0, newRow);
						}

						ganttRow.hasHigherRowIndex = true;
						ganttRow.isLastOfGroupedTasks = false;
					}

					ganttRow.tasks =
						(shifts[task.id] == null ? [] : shifts[task.id].filter(function(x) { return !x.row_index || x.row_index <= 1; }).map(function(x) { return self.dataService.createScheduleTaskFromShift(x, task, self.viewTypeId, self.showMobileView, self.fromDate, self.toDate) }));
				}
			});

			if (this.isInitializing) {
				var offsetGantt;
				var beginningOfPreviousWeek = moment().startOf('week').subtract(1, 'week');

				if (beginningOfPreviousWeek >= moment(this.fromDate) && beginningOfPreviousWeek <= moment(this.toDate)) {
					var offsetBeginningOfPreviousWeek = moment(beginningOfPreviousWeek).diff(this.fromDate, 'days');
					offsetGantt = offsetBeginningOfPreviousWeek * this.columnWidth;
				} else {
					offsetGantt = 0;
				}

				var scrollableDiv = document.querySelector('#gantt-scrollable-div');

				// don't scroll when we're running e2e tests
				if (scrollableDiv != null && this.user && !(this.user.lastname == "Langeveld" && this.user.customer != null && this.user.customer.name == "MC Neurologie")) {
					setTimeout(function() {
						scrollableDiv.scrollLeft = offsetGantt;
					}, 10);	
				}

				this.isInitializing = false;
			}

			this.isLoading = false;
			
			this.detectChanges();
		});
	}

	getTooltipText(element) {
		var task = JSON.parse(element.getAttribute("taskasjson"));

		// compute dateLabel not in this content but in a controller/service because moment doesn't work well in templates
		var tooltipText = "";

		// TODO: case statement with enums
		if (task.ganttTaskType == "unassignedCandidateCount") {
			if (task.unassignedCandidateCount > 0) {
				tooltipText = "<small>" +
					task.dateLabel + ": " + task.unassignedCandidateCount + " niet toegewezen " + this.environmentService.translateForIndustry('candidate') + (task.unassignedCandidateCount == 1 ? "" : "en") + "</small><br />" +
					task.candidateNames + "<br />" +
				"</small>";
			}
		} else if (task.ganttTaskType == "unassignedShiftCount") {
			if (task.unassignedShiftCount > 0) {
				tooltipText = "<small>" +
					task.dateLabel + ": " + task.unassignedShiftCount + " niet toegewezen " + (task.unassignedShiftCount == 1 ? "taak" : "taken") + "</small><br />" +
					task.taskNames + "<br />" +
				"</small>";
			}
		} else if (task.ganttTaskType == "shiftAccumulationTypeCount") {
			tooltipText = "<small>" +
				task.dateLabel + ": " + task.name + " " + this.environmentService.translateForIndustry('candidate') + (task.name == "1" ? "" : "en") + " voor " + task.tooltipSubtitle + task.tooltipTitle + "</small><br />" +
				task.candidateNames + "<br />" +
			"</small>";
		} else if (task.isSpecialEvent) {
			tooltipText = "<small>" +
				task.dateLabel + ": " + (task.isPublicHoliday ? "Feestdag" : "Evenement") + "</small><br />" +
				task.name + "<br />" +
			"</small>";
		} else {
			tooltipText = "<small>" + task.dateLabel + ": " + task.task.name +
				(task.shift.task_periodic_candidate_id == null && task.shift.task_variant_name != null ? " (" + task.shift.task_variant_name + ")" : "") +
				(task.shift.location == null ? "" : " (" + task.shift.location.alias + ")") + "</small><br />" +
				"<span class='" +
					(
						(task.shift.task_periodic_candidate_id == null ? task.shift.periodic_candidate_id == null : task.shift.task_variant_name == null) ?
						"tooltip-italic" :
						""
					) +
					"'>" + task.tooltipTitle + "</span><br />";

			task.shift.comment_parts.forEach(function(x) {
				if (x[0] == null) {
					tooltipText += "<small><span class='tooltip-warning'>" + x[1] + "</span><br /></small>";
				} else {
					tooltipText += "<small><span class='tooltip-warning'><span class='tooltip-underline'>" + moment(x[0]).format("D/M") + "</span>: " + x[1] + "</span><br /></small>";
				}
			});

			if (task.violations != null) {
				if (task.violations[1] != null) {
					tooltipText += "<small><span class='tooltip-error'>" + task.violations[1] + "</span><br /></small>";
				}
				if (task.violations[2] != null) {
					tooltipText += "<small><span class='tooltip-warning'>" + task.violations[2] + "</span><br /></small>";
				}
				if (task.violations[3] != null) {
					tooltipText += "<small><span class='tooltip-error'>" + task.violations[3] + "</span><br /></small>";
				}
			}
		}

		return tooltipText;
	}

	buildGanttChart() {
		var self = this;

		var buildGanttChartPromises = [];
		this.data = [];
		this.dataKpiRows = [];

		this.environmentService.refreshShiftCountOverview([]);

		this.detectChanges();

		forkJoin([
			this.http.get('shift_accumulation_types', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }),
			this.http.get('time_slots')
		]).subscribe(shiftAccumulationTypesResponseAndTimeSlots => {
			
			this.shiftAccumulationTypes = shiftAccumulationTypesResponseAndTimeSlots[0];
			this.workingDayTimeSlots = (<any>shiftAccumulationTypesResponseAndTimeSlots[1]).filter(function(x) { return x.is_part_of_working_day; });

			this.dataKpiRows.push({
				id: "special",
				name: "Bijzonderheden",
				classes: (this.user.isAdmin && !this.showMobileView) || this.shiftAccumulationTypes.length > 0 ? "" : "separatingTask"
			});

			if ((this.user.isAdmin && !this.showMobileView) || this.shiftAccumulationTypes.length > 0) {
				this.hasCountsDisplayGroup = true;
				this.countsDisplayGroupMultiplier = 1;

				this.dataKpiRows.push({
					name: "Tellingen",
					id: "task_display_group_0",
					taskDisplayGroupId: 0,
					isDisplayGroup: true,
					isHidden: false, //task.task_display_group.is_hidden
				});	
			}

			buildGanttChartPromises.push(this.http.get('special_events', { params: { schedule_period_id: this.selectedSchedulePeriod.id } }));

	 		this.shiftAccumulationTypes.forEach(function(shiftAccumulationType, index) {
				if (self.workingDayTimeSlots.length == 0 || (self.workingDayTimeSlots.length == 1 && self.workingDayTimeSlots[0].id == 0)) {
					self.dataKpiRows.push({
						id: "shiftAccumulationType_" + shiftAccumulationType.id,
						name: shiftAccumulationType.name,
						classes: (self.user.isAdmin && !self.showMobileView) || index != self.shiftAccumulationTypes.length - 1 ? "" : "separatingTask",
						isGroupedRow: false,
						belongsToTaskDisplayGroupId: 0
					});
				} else {
					for(var i = 0; i < self.workingDayTimeSlots.length; i++) {
						var timeSlot = self.workingDayTimeSlots[i];

						self.dataKpiRows.push({
							id: "shiftAccumulationType_" + shiftAccumulationType.id + "_" + timeSlot.id,
							baseName: i == 0 ? shiftAccumulationType.name : "",
							classes: (self.user.isAdmin && !self.showMobileView) || index != self.shiftAccumulationTypes.length - 1 || (i + 1) != self.workingDayTimeSlots.length ? "" : "separatingTask",
							isGroupedRow: true,
							name: self.dataService.capitalize(timeSlot.name),
							isLastOfGroupedTasks: (i + 1) == self.workingDayTimeSlots.length,
							belongsToTaskDisplayGroupId: 0
						});
					}
				}
			});

			buildGanttChartPromises.push(this.http.get('shift_accumulation_types/counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }));

			if (this.user.isAdmin && !this.showMobileView) {
				var unassignedCandidateCountName = "Niet toegewezen" + (this.showMobileView ? "" : " " + this.environmentService.translateForIndustry('candidates'));

				if (this.workingDayTimeSlots.length == 0 || (this.workingDayTimeSlots.length == 1 && this.workingDayTimeSlots[0].id == 0)) {
					this.dataKpiRows.push({
						id: "unassignedCandidateCount",
						name: unassignedCandidateCountName,
						isGroupedRow: false,
						belongsToTaskDisplayGroupId: 0
					});
				} else {
					for(var i = 0; i < this.workingDayTimeSlots.length; i++) {
						var timeSlot = this.workingDayTimeSlots[i];

						self.dataKpiRows.push({
							id: "unassignedCandidateCount" + timeSlot.id,
							baseName: i == 0 ? unassignedCandidateCountName : "",
							isGroupedRow: true,
							name: self.dataService.capitalize(timeSlot.name),
							isLastOfGroupedTasks: (i + 1) == this.workingDayTimeSlots.length,
							belongsToTaskDisplayGroupId: 0
						});
					}
				}

				buildGanttChartPromises.push(this.http.get('tasks/unassigned_candidate_counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }));

				this.dataKpiRows.push({
					id: "unassignedShiftCount",
					name: "Niet toegewezen taken",
					classes: "separatingTask",
					belongsToTaskDisplayGroupId: 0
				});

				buildGanttChartPromises.push(this.http.get('tasks/unassigned_shift_counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }));
			}

			// somehow if gantt tasks for Tasks are being created and the count-rows (unassignedCandidateCounts, unnassignedShiftCounts etc) are
			// also being created at the same time, rendering for count-rows doesn't happen
			// So we first wait for the creation of count-rows to be finished, then put those in the gantt chart at one go (synchronously) and after that
			// we put the data in whenever it is finished (async)
			// We could also wait for everything to be created and then put it in the data variable at one go, but it's nicer to let the gantt chart
			// be build up incrementally, looks better and for performance probably also better
			forkJoin(buildGanttChartPromises).subscribe(result => {
				this.refreshSpecialEventsAndTimespans(result[0]);
				this.refreshShiftAccumulationTypeCounts(result[1]);

				if (this.user.isAdmin && !this.showMobileView) {
					this.refreshUnassignedCandidateCounts(result[2]);
					this.refreshUnassignedShiftCounts(result[3]);
				}

				this.http.get('tasks/for_schedule',
					{
						params: {
							schedule_period_id: this.selectedSchedulePeriod.id,
							day_schedule_tab_id: this.selectedDayScheduleTabId	
						}
					}
				).subscribe(data => {
					this.tasks = data;

					var currentTaskDisplayGroupName = null;
					var currentTaskDisplayGroupIsHidden = false;
					var currentTaskDisplayGroupIsInaccessible = false;
					var currentPeriodicCandidateId = null;
					var currentTaskBaseName = null;

					this.numberOfTaskDisplayGroups = 0;

					for(var i=0; i < this.tasks.length; i++) {
						this.tasks[i].baseName = this.tasks[i].name;

						if (this.tasks[i].baseName.substring(this.tasks[i].baseName.length - 8) == " ochtend") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].baseName.substring(0, this.tasks[i].baseName.length - 8);
							this.tasks[i].namePostfix = "Ochtend";
						} else if (this.tasks[i].baseName.substring(this.tasks[i].baseName.length - 7) == " middag") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].baseName.substring(0, this.tasks[i].baseName.length - 7);
							this.tasks[i].namePostfix = "Middag";
						} else if (this.tasks[i].baseName.substring(0, 13) == "Leidschenveen") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].name.substring(14, this.tasks[i].name.length - 9);
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 9);
						} else if (this.tasks[i].baseName.substring(0, 11) == "Voorschoten") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].name.substring(12, this.tasks[i].name.length - 9);
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 9);
						} else if (this.tasks[i].baseName.substring(0, 10) == "Zoetermeer") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].name.substring(11, this.tasks[i].name.length - 8);
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
						} else if (this.tasks[i].baseName.substring(0, 10) == "Driesprong") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].name.substring(11, this.tasks[i].name.length - 8);
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
						} else if (this.tasks[i].baseName.substring(0, 5) == "SPOED") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = this.tasks[i].name.substring(6, this.tasks[i].name.length - 8);
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 8);
						} else if (this.tasks[i].baseName.substring(0, 19) == "Schoolfotografie bo") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = "Schoolfotografie bo";
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 4);
						} else if (this.tasks[i].baseName.substring(0, 19) == "Schoolfotografie vo") {
							this.tasks[i].hasMorningOrAfternoonPostfix = true;
							this.tasks[i].baseName = "Schoolfotografie vo";
							this.tasks[i].namePostfix = this.tasks[i].name.substring(this.tasks[i].name.length - 4);
						}						
					}

					for(var i=0; i < this.tasks.length; i++) {
						this.tasks[i].isPartOfGroup = 
							this.tasks[i].hasMorningOrAfternoonPostfix && 
								(
									(i > 0 && this.tasks[i-1].hasMorningOrAfternoonPostfix && this.tasks[i-1].baseName == this.tasks[i].baseName)
									||
									(i < (this.tasks.length-1) && this.tasks[i+1].hasMorningOrAfternoonPostfix && this.tasks[i+1].baseName == this.tasks[i].baseName)
								);
					}

					for(var i=0; i < this.tasks.length; i++) {
						var task = this.tasks[i];
					
						var isDifferentDisplayGroup = task.task_display_group != null && task.task_display_group.name != currentTaskDisplayGroupName;

						if (isDifferentDisplayGroup) {
							this.taskDisplayGroupVisibility[task.task_display_group_id] = !task.task_display_group.is_hidden;

							if (task.task_display_group.is_inaccessible) {
								currentTaskDisplayGroupIsInaccessible = true;
							} else {
								self.data.push({
									name: this.showMobileView ? self.truncatePipe.transform(task.task_display_group.name, 20) : task.task_display_group.name,
									id: "task_display_group_" + task.task_display_group_id,
									taskDisplayGroupId: task.task_display_group_id,
									isDisplayGroup: true,
									isHidden: task.task_display_group.is_hidden
								});
								self.numberOfTaskDisplayGroups += 1;
	
								currentTaskDisplayGroupName = task.task_display_group.name;

								currentTaskDisplayGroupIsHidden = task.task_display_group.is_hidden;
							}
							 
							currentTaskDisplayGroupIsInaccessible = task.task_display_group.is_inaccessible;							
						}

						if (!currentTaskDisplayGroupIsInaccessible) {
							var isFirstOfPeriodCandidateTasks = task.periodic_candidate != null && currentPeriodicCandidateId != task.periodic_candidate_id;
							var isLastOfPeriodCandidateTasks = task.periodic_candidate != null && ((i+1) == this.tasks.length || this.tasks[i+1].periodic_candidate_id != task.periodic_candidate_id);
							var isFirstOfTasksWithSameBaseName = currentTaskBaseName != task.baseName || (this.tasks[i].namePostfix && this.tasks[i].namePostfix.substring(0,2) == "F1");
							var isLastOfTasksWithSameBaseName = task.isPartOfGroup && ((i+1) == this.tasks.length || this.tasks[i+1].baseName != task.baseName || this.tasks[i+1].namePostfix.substring(0,2) == "F1");
							
							self.data.push({
								name: task.isPartOfGroup ? task.namePostfix : task.name,
								id: task.id,
								isDisplayGroup: false, // needed to make sure watcher is removed from ng-if (see https://stackoverflow.com/questions/30904190/one-time-binding-with-ng-if-in-angular)
								belongsToTaskDisplayGroupId: task.task_display_group_id,
								isGroupedRow: (task.periodic_candidate != null || task.isPartOfGroup),
								baseName: task.isPartOfGroup ? (isFirstOfTasksWithSameBaseName ? task.baseName : "") : (isFirstOfPeriodCandidateTasks ? (new Candidate(task.periodic_candidate.candidate)).lastnameWithInfix() : ""),
								isLastOfGroupedTasks: isLastOfPeriodCandidateTasks || isLastOfTasksWithSameBaseName,
								classes: null
							});

							currentPeriodicCandidateId = task.periodic_candidate_id;
							currentTaskBaseName = task.baseName;
						}
					}

					this.data.push({
						id: "dummySoScrollbarWontOverlapLastRow",
						name: "",
						classes: ""
					});

					this.refreshTasks();
				});
			});
		});
	}

	changeSelectedDayScheduleTab(dayScheduleTabId) {
		this.isLoading = true;

		this.selectedDayScheduleTabId = dayScheduleTabId;

		this.http.post('day_schedule_tabs/set_last_selected_by_user',
			{
				last_selected_day_schedule_tab_id: this.selectedDayScheduleTabId
			}			
		).subscribe();

		this.isMultiSelect = false;
		this.selectedGanttTasksForMultiSelect = [];

		this.buildGanttChart();
	}

	// day = 1, week = 2, entire = 3
	setViewType(viewTypeId, isInitialization) {
		this.isLoading = true;
		this.isInitializing = true;

		this.viewTypeId = viewTypeId;

		switch(this.viewTypeId) {
			case 1:
				this.weekForViewType = null;
				this.columnWidth = 28 * 6; 
				break;
			case 2:
				this.dayForViewType = null;
				this.columnWidth = this.showMobileView ? 28 : 28 * 6;
				break;
			case 3:
				this.dayForViewType = null;
				this.weekForViewType = null;
				this.columnWidth = 28;
				break;
		}

		if (!isInitialization) {
			this.setStartEndDate();
			this.buildGanttChart();	
		}
	}

	setDayOrWeekNumber() {
		this.isLoading = true;

		this.setStartEndDate();
		this.buildGanttChart();
	}

	initialize() {
		this.showMobileView = (window.innerWidth && !isNaN(window.innerWidth) && window.innerWidth < 500);

		this.isAutoPlanning = false;

		this.selectedSchedulePeriod = this.environmentService.getSchedulePeriodSelection();

		this.selection = { taskDisplayGroupCombinationId: null };

		this.selectedGanttTasksForMultiSelect = [];

		if (this.selectedSchedulePeriod) {
			this.http.get<any>('task_display_group_combinations',
				{ 
					params: {
						schedule_period_id: this.selectedSchedulePeriod.id
					}
				}
			).subscribe(taskDisplayGroupCombinations => {
				this.taskDisplayGroupCombinations = taskDisplayGroupCombinations;
	
				this.taskDisplayGroupCombinations.forEach(function(taskDisplayGroupCombination) {
					taskDisplayGroupCombination.taskDisplayGroupIds = taskDisplayGroupCombination.task_display_groups.map(function(x) { return x.id; }).sort().join(",");
				});
	
				this.refreshTaskDisplayGroupCombinationSelection();
			});	
		}

		this.autoPlanSelection = { weekNumber: null };

		this.user = this.environmentService.getUser();

		if (this.user == undefined) {
			this.router.navigate(["/login"]);
		}

		this.http.get<any>("task_display_groups/index_for_user_for_schedule_period", 
			{ 
				params: {
					user_id: this.user.id,
					schedule_period_id: this.selectedSchedulePeriod.id	
				}
			}
		).subscribe(x => {
			this.restrictedAdminTaskDisplayGroupIds = x.filter(function(y) { return !y.is_allowed_for_admin }).map(function(y) { return y.id; });
		});

		this.http.get<any>("task_types/index_for_user", 
			{ 
				params: {
					user_id: this.user.id
				}
			}
		).subscribe(x => {
			this.restrictedAdminTaskTypeIds = x.filter(function(y) { return !y.is_allowed_for_admin }).map(function(y) { return y.id; });
		});

		this.http.get<any>("shifts/self_rostering_shift_ids", 
			{ 
				params: {
					schedule_period_id: this.selectedSchedulePeriod.id
				}
			}
		).subscribe(x => {
			this.selfRosteringShiftIds = x;
		});

		this.taskDisplayGroupVisibility[0] = true;

		this.setViewType(this.showMobileView ? 1 : (this.user.customer.show_week_view ? 2 : 3), true);

		this.isPublishedPartly = !this.selectedSchedulePeriod.is_published && 
			(
				(
					this.selectedSchedulePeriod.task_display_groups != null && 
					this.selectedSchedulePeriod.task_display_groups.filter(function(x) { return x.is_published; }).length > 0
				) ||
				(
					this.selectedSchedulePeriod.candidate_type_schedule_periods != null && 
					this.selectedSchedulePeriod.candidate_type_schedule_periods.filter(function(x) { return x.is_published; }).length > 0
				)
			);

		this.isPublishedOrPartlyPublished = this.selectedSchedulePeriod.is_published || this.isPublishedPartly;

		this.isVisible = this.user != null && 
			(
				this.user.isAdmin || 
					(
						this.isPublishedOrPartlyPublished && 
						(
							this.user.candidateId == null || 
							this.user.schedulePeriodIds.indexOf(this.selectedSchedulePeriod.id) >= 0
						)
					)
			);

		if (this.isVisible) {
			this.isInitializing = true;
			this.isLoading = true;
			this.isMultiSelect = false;
			
			this.detectChanges();

			this.dayForViewType = null;
			this.weekForViewType = null;
			this.setStartEndDate();

			this.http.get('day_schedule_tabs').subscribe(dayScheduleTabs => {
				this.dayScheduleTabs = dayScheduleTabs;
				if (this.dayScheduleTabs.length > 0) {
					var lastSelectedDayScheduleTabs = this.dayScheduleTabs.filter(function(x) { return x.is_last_selected_by_user; });

					if (lastSelectedDayScheduleTabs.length > 0) {
						this.selectedDayScheduleTabId = lastSelectedDayScheduleTabs[0].id;
					} else {
						this.selectedDayScheduleTabId = this.dayScheduleTabs[0].id;
					}
				}
	
				this.buildGanttChart();
			});
		} else {
			// When switching from visible to non-visible schedule period, update the view
			this.detectChanges();
		}
	}
	
	getLength(from, to) {
		return moment(to).diff(moment(from), 'days');
	}
	
	getOffset(from) {
		return moment(from).diff(this.fromDate, 'days');
	}
	
	getLengthRelative(from, to, entireFrom, entireTo) {
		return this.getLength(from, to) / this.getLength(entireFrom, entireTo);
	}
	
	getOffsetRelative(from, entireFrom, entireTo) {
		return from.diff(entireFrom, 'days') / entireTo.diff(entireFrom, 'days');
	}
	
	refreshTaskDisplayGroupCombinationSelection() {
		return this.http.get<any>('user_hidden_task_display_groups',
			{ 
				params: {
					schedule_period_id: this.selectedSchedulePeriod.id
				}
			}
		).subscribe(userHiddenTaskDisplayGroups => {
			var userHiddenTaskDisplayGroupIds = userHiddenTaskDisplayGroups.map(function(x) { return x.task_display_group_id }).sort().join(",");

			var newSelectedId = null;

			this.taskDisplayGroupCombinations.forEach(function(taskDisplayGroupCombination) {
				if (taskDisplayGroupCombination.taskDisplayGroupIds == userHiddenTaskDisplayGroupIds) {
					newSelectedId = taskDisplayGroupCombination.id;
				}
			});

			this.selection.taskDisplayGroupCombinationId = newSelectedId;
		})
	}
	
	syncScroll(event) {
		this.horizontalScrollReceiver1.scrollLeft = event.scrollLeft;
		this.verticalScrollReceiver1.scrollTop = event.scrollTop;
		this.verticalScrollReceiver2.scrollTop = event.scrollTop;
	}
	
	toggleTaskDisplayGroup(ganttRow) {
		ganttRow.isHidden = !ganttRow.isHidden
		this.taskDisplayGroupVisibility[ganttRow.taskDisplayGroupId] = !ganttRow.isHidden;

		if (ganttRow.taskDisplayGroupId != 0) {
			if (ganttRow.isHidden) {
				var userHiddenTaskDisplayGroup = new UserHiddenTaskDisplayGroup(ganttRow.taskDisplayGroupId);
	
				this.http.post('user_hidden_task_display_groups',
					{
						user_hidden_task_display_group: userHiddenTaskDisplayGroup
					}
				).subscribe(response => {	
					this.refreshTaskDisplayGroupCombinationSelection();
				});
			} else {
				this.http.delete('user_hidden_task_display_groups/' + ganttRow.taskDisplayGroupId).subscribe(response => {
					this.refreshTaskDisplayGroupCombinationSelection();
				});
			}
		}
	}
	
	changeSelectedTaskDisplayGroupCombinationId() {
		var self = this;
		this.isLoading = true;
		this.detectChanges();

		var selectedTaskDisplayGroupCombination = this.selection.taskDisplayGroupCombinationId == null ? null :
			this.taskDisplayGroupCombinations.filter(function(x) { return x.id == self.selection.taskDisplayGroupCombinationId; })[0];
		var taskDisplayGroupIds = selectedTaskDisplayGroupCombination == null ? null :
			selectedTaskDisplayGroupCombination.task_display_groups.map(function(x) { return x.id });

		var userHiddenTaskDisplayGroupIdsToAdd = [];
		var userHiddenTaskDisplayGroupIdsToDelete = [];

		this.data.forEach(function(ganttRow) {
			if (ganttRow.isDisplayGroup) {
				if (selectedTaskDisplayGroupCombination == null) {
					if (ganttRow.isHidden) {
						userHiddenTaskDisplayGroupIdsToDelete.push(ganttRow.taskDisplayGroupId);
					}
				}
				else {
					var combinationContainsTaskDisplayGroup = taskDisplayGroupIds.indexOf(ganttRow.taskDisplayGroupId) >= 0;

					if (ganttRow.isHidden && !combinationContainsTaskDisplayGroup) {
						userHiddenTaskDisplayGroupIdsToDelete.push(ganttRow.taskDisplayGroupId);
					} else if (!ganttRow.isHidden && combinationContainsTaskDisplayGroup) {
						userHiddenTaskDisplayGroupIdsToAdd.push(ganttRow.taskDisplayGroupId);
					}
				}
			}
		});

		this.http.post('user_hidden_task_display_groups/bulk_create',
			{ task_display_group_ids: userHiddenTaskDisplayGroupIdsToAdd }
		).subscribe(response => {
			this.http.post('user_hidden_task_display_groups/bulk_destroy',
				{ task_display_group_ids: userHiddenTaskDisplayGroupIdsToDelete }
			).subscribe(response2 => {
				this.buildGanttChart();
			});
		});
	}
	
	addToGanttChart(groupedShifts) {
		var self = this;
		
		Object.keys(groupedShifts).forEach(function(key) {

			var task = self.tasks.filter(function(x) { return x.id == key })[0];

			var indexOfGanttRow = self.data.map(function(x) { return x.id.toString() }).indexOf(key.toString());
			var ganttRow = self.data[indexOfGanttRow];

			groupedShifts[key].forEach(function(shift) {
				if (!shift.row_index || shift.row_index < 2) {
					ganttRow.tasks.push(self.dataService.createScheduleTaskFromShift(shift, task, self.viewTypeId, self.showMobileView, self.fromDate, self.toDate));
				} else {
					// check whether row for given row_index exists, create if it doesn't
					var ganttRowsForRowIndex = self.data.filter(function(x) { return x.id == (key + "_" + shift.row_index); });
					var ganttRowForRowIndex;

					if (ganttRowsForRowIndex.length == 0) {

						var newRow = {
							baseName: "",
							belongsToTaskDisplayGroupId: ganttRow.belongsToTaskDisplayGroupId,
							classes: null,
							id: task.id.toString() + "_" + shift.row_index.toString(),
							isDisplayGroup: false,
							isGroupedRow: true,
							isLastOfGroupedTasks: ganttRow.isLastOfGroupedTasks,
							name: "",
							tasks: [],
							hasHigherRowIndex: false
						};
							
						self.data.splice(indexOfGanttRow + shift.row_index - 1, 0, newRow);

						ganttRowForRowIndex = newRow;

						if (shift.row_index == 2) {
							ganttRow.hasHigherRowIndex = true;
							ganttRow.isLastOfGroupedTasks = false;
						} else {
							var previousGanttRow = self.data.filter(function(x) { return x.id == (key + "_" + (shift.row_index - 1)); })[0];
							previousGanttRow.hasHigherRowIndex = true;
							previousGanttRow.isLastOfGroupedTasks = false;
						}
					} else {
						ganttRowForRowIndex = ganttRowsForRowIndex[0];
					}

					ganttRowForRowIndex.tasks.push(self.dataService.createScheduleTaskFromShift(shift, task, self.viewTypeId, self.showMobileView, self.fromDate, self.toDate));
				}
			});
		});
	}
	
	deleteFromGanttChart(groupedShifts) {
		var self = this;
		
		Object.keys(groupedShifts).forEach(function(key) {

			var task = self.tasks.filter(function(x) { return x.id == key })[0];

			var ganttRow = self.data.filter(function(x) { return x.id == key })[0];

if (!ganttRow) {
	console.log("----------");
	console.log(key);
	console.log(self.data);
}

			groupedShifts[key].forEach(function(shift) {

				var index = -1;
				ganttRow.tasks.some(function(el, i) {
				    if (el.shift.id == shift.id) {
				        index = i;
				        return true;
				    }
				});

				if (index >= 0) {
					ganttRow.tasks.splice(index, 1);
				}
			});
		});
	}

	deleteClonedShiftsFromGanttChart(taskIds) {
		var self = this;
		
		taskIds.forEach(function(taskId) {
			var ganttRow = self.data.filter(function(x) { return x.id == taskId })[0];

			self.removeGanttTasksFromRow(ganttRow);

			// also remove gantt tasks from extra row_index rows
			var searchingForExtraRows = true;
			var rowIndex = 2;
			
			while (searchingForExtraRows) {
				var taskIdWithRowIndex = taskId.toString() + "_" + rowIndex.toString();

				var ganttRows = self.data.filter(function(x) { return x.id == taskIdWithRowIndex });

				if (ganttRows.length == 0) {
					searchingForExtraRows = false;
				} else {
					self.removeGanttTasksFromRow(ganttRows[0]);
				}

				rowIndex++;
			}
		});
	}

	removeGanttTasksFromRow(ganttRow) {
		for (var index = ganttRow.tasks.length-1; index >= 0; index--) {
			if (ganttRow.tasks[index].shift.id == null) {
				ganttRow.tasks.splice(index, 1);
			}
		}
	}

	
	clickGanttTask(task) {
		if (
				(this.user.isAdmin && (!this.showMobileView || this.user.customer.id == 19) && !task.shift.is_cloned_shift &&
					this.restrictedAdminTaskDisplayGroupIds.filter(function(x) { return x == task.task.task_display_group_id }).length == 0 &&
					this.restrictedAdminTaskTypeIds.filter(function(x) { return x == task.task.task_type_id }).length == 0) ||
				((this.user.customer.id == 27) && this.selfRosteringShiftIds.filter(function(x) { return x == task.shift.id }).length > 0)
		) {

			if (this.isMultiSelect) {
				if (task.isSelectedForMultiSelect) {
					task.isSelectedForMultiSelect = false;

					var index = -1;
					for (var i = 0; i < this.selectedGanttTasksForMultiSelect.length; i++) {
						if (this.selectedGanttTasksForMultiSelect[i] == task) {
							index = i;
							break;
						}
					}

					if (index > -1) {
					  this.selectedGanttTasksForMultiSelect.splice(index, 1);
					}
				} else {
					task.isSelectedForMultiSelect = true;
					this.selectedGanttTasksForMultiSelect.push(task);
				}
			} else {
				this.openAssignModal(task);
			}
		}
	}

	openAssignModalMultiSelect() {
		this.openAssignModal(this.selectedGanttTasksForMultiSelect.length == 1 ? this.selectedGanttTasksForMultiSelect[0] : null);
	}

	openAssignModal(task) {
	    var bsModalRef = this.bsModalService.show(AssignTaskModalComponent, {
			initialState: {
				task: task,
				selectedGanttTasksForMultiSelect: this.selectedGanttTasksForMultiSelect
			}, 
			class: 'xlModal'
		});

		bsModalRef.content.action.subscribe((shiftAndDateSelectionAndAllocationRequestParts) => {
			this.isLoading = true;

			var shift = shiftAndDateSelectionAndAllocationRequestParts[0];
			var dateSelection = shiftAndDateSelectionAndAllocationRequestParts[1];
			var allocationRequestParts = shiftAndDateSelectionAndAllocationRequestParts[2];

			var dateSelectionValues = [];

			if (!this.isMultiSelect || this.selectedGanttTasksForMultiSelect.length == 1) {
				Object.keys(dateSelection).sort(function(a, b) { return (<any> moment(a)) - (<any> moment(b)); }).forEach(function(date) {
					dateSelectionValues.push(dateSelection[date]);
				});
			}

			var noOrAcceptedChangeHighlightedShift = true;

			if (
				this.user.customer.id != 26 &&
				(
					(shift.task_is_open_shift_highlighted && shift.is_cancelled != shift.former_is_cancelled) ||
					(this.selectedGanttTasksForMultiSelect.filter(function(x) { return x.shift.task_is_open_shift_highlighted && shift.is_cancelled != x.shift.former_is_cancelled; }).length > 0)
				)
			){
				this.isLoading = false;

				noOrAcceptedChangeHighlightedShift = confirm("Er zijn 1 of meer taken gevonden waarvoor geldt dat een open taak van deze activiteit benadrukt moet worden " + 
					"(zie het veld 'Benadruk open taken' bij activiteiten) en dat deze zojuist is " + (shift.is_cancelled ? "geannuleerd" : "opengezet") + ". " +
					"Weet u zeker dat u wilt doorgaan?");				
			}

			if (noOrAcceptedChangeHighlightedShift) {

				this.isLoading = true;

				if (shift.task_periodic_candidate_id == null) {

					this.http.post<any>("shifts/assigned_shift_contains_allocation_request_parts",
						{
							id: this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1 ? 0 : task.shift.id,
							is_bulk_update: this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1,
							shift: shift,
							date_selection: dateSelectionValues,
							allocation_request_parts: allocationRequestParts,
							shift_ids: this.selectedGanttTasksForMultiSelect.map(function(x) { return x.shift.id; })
						}
					).subscribe(result => {

						var skipAllocationRequestParts = false;

						if (shift.periodic_candidate_id != null && !shift.is_unsplittable && result.contains_accepted_allocation_request_parts) {
							this.isLoading = false;

							skipAllocationRequestParts = confirm("Er zijn 1 of meer geaccepteerde verzoeken 'Afwezig' gevonden in de periode van " +
								"de toegewezen taak/taken. Wilt u dat de dagen van deze verzoeken worden overgeslagen bij de toewijzing?");
						}

						this.isLoading = true;

						this.http.put("shifts/" + (this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1 ? '0' : task.shift.id.toString()),
							{
								is_bulk_update: this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1,
								shift: shift,
								date_selection: dateSelectionValues,
								allocation_request_parts: allocationRequestParts,
								shift_ids: this.selectedGanttTasksForMultiSelect.map(function(x) { return x.shift.id; }),
								skip_accepted_allocation_request_parts: skipAllocationRequestParts,
								day_schedule_tab_id: this.selectedDayScheduleTabId	
							}
						).subscribe(data => {
							this.isLoading = false;

							this.refreshSchedulePartly(data, false);
						}, (response) => {
							this.isLoading = false;
							if (response.error) {
								this.toastr.error(response.error.error);
							}
							console.error(response);
						});
					});

				} else {

					this.http.put("shifts/" + (this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1 ? '0' : task.shift.id.toString()),
						{
							is_bulk_update: this.isMultiSelect && this.selectedGanttTasksForMultiSelect.length != 1,
							shift: shift,
							date_selection: dateSelectionValues,
							allocation_request_parts: allocationRequestParts,
							shift_ids: this.selectedGanttTasksForMultiSelect.map(function(x) { return x.shift.id; }),
							skip_accepted_allocation_request_parts: 'false',
							day_schedule_tab_id: this.selectedDayScheduleTabId	
						}
					).subscribe(data => {
						this.isLoading = false;
						this.refreshSchedulePartly(data, false);
					}, (response) => {
						this.isLoading = false;					
						this.toastr.error(response.error.error);
						console.error(response);
					});
				}

			} else {
				shift.is_cancelled = shift.former_is_cancelled;
				shift.periodic_candidate_id = shift.former_periodic_candidate_id;
			}
		//}, (err) => {
    	});
		
		this.isLoading = false;
	}

	refreshSchedulePartly(data, hasTemporaryChanges) {
		var addedShifts = JSON.parse(data['added']);
		var changedShifts = JSON.parse(data['changed']);
		var deletedShifts = JSON.parse(data['deleted']);
		var taskIds = data['delete_cloned_shifts_for_task_ids'];

		if (taskIds != undefined && taskIds != null) {
			this.deleteClonedShiftsFromGanttChart(taskIds);
		}

		this.deleteFromGanttChart(deletedShifts);
		this.deleteFromGanttChart(changedShifts);
		this.addToGanttChart(changedShifts);
		this.addToGanttChart(addedShifts);

		var buildGanttChartPromises = [
			this.http.get('shift_accumulation_types/counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } })
		];
		
		if (this.user.isAdmin && !this.showMobileView) {
			buildGanttChartPromises.push(this.http.get('tasks/unassigned_candidate_counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }));
			buildGanttChartPromises.push(this.http.get('tasks/unassigned_shift_counts', { params: { schedule_period_id: this.selectedSchedulePeriod.id, day_schedule_tab_id: this.selectedDayScheduleTabId } }));
		}
		
		forkJoin(buildGanttChartPromises).subscribe(result => {
			this.refreshShiftAccumulationTypeCounts(result[0]);

			if (this.user.isAdmin && !this.showMobileView) {
				this.refreshUnassignedCandidateCounts(result[1]);
				this.refreshUnassignedShiftCounts(result[2]);
			}

			this.environmentService.refreshTotalOpenAllocationRequestCount();
			if (this.user.id != 587) {
				this.environmentService.refreshShiftCountOverview(hasTemporaryChanges ? changedShifts : []);
			}
		
			this.detectChanges();
		});

		this.isMultiSelect = false;
		this.selectedGanttTasksForMultiSelect = [];
	}

	startMultiSelect() {
		this.isMultiSelect = true;
	}

	cancelMultiSelect() {
		this.isLoading = true;
		this.isMultiSelect = false;
		this.selectedGanttTasksForMultiSelect = [];
		// TODO uncheck all selected
		this.buildGanttChart();
	}

	resetDefaultAssignments() {
		if (window.confirm('Weet je zeker dat je de geselecteerde activiteiten toe wilt laten wijzen volgens het basisrooster? De huidige toewijzingen van de geselecteerde activiteiten worden overschreven.')) {
			this.isLoading = true;
			
			this.http.post("shifts/reset_default_assignments",
				{
					shift_ids: this.selectedGanttTasksForMultiSelect.map(function(x) { return x.shift.id; }),
					day_schedule_tab_id: this.selectedDayScheduleTabId	
				}
			).subscribe(data => {
				this.isLoading = false;

				this.refreshSchedulePartly(data, false);
			}, (response) => {
				this.isLoading = false;
				this.toastr.error(response.error.error);
				console.error(response);
				this.cancelMultiSelect();
			});
		}
	}
	
	ngOnInit() {
		this.environmentService.selectedSchedulePeriodChanged$
			.pipe(takeUntil(this.destroyed$))
			.subscribe(schedulePeriodId => {
				this.initialize();
			});
		
		// because isSchedulePeriodSet in "<router-outlet *ngIf="isSchedulePeriodSet"></router-outlet>" in loggedInLayoutComponent
		// it's a given that schedule period has been set, therefore we can start initialize()
		// (if that would not have been done we would need to check first if schedule period has been set, but then it could be set
		// a fraction later which would cause initialize() to run twice)
		this.initialize();
	}
	
	ngAfterViewInit() {
		this.horizontalScrollReceiver1 = document.querySelectorAll('.myHorizontalScrollReceiver')[0];
		var verticalScrollReceivers = document.querySelectorAll('.myVerticalScrollReceiver');
		this.verticalScrollReceiver1 = verticalScrollReceivers[0];
		this.verticalScrollReceiver2 = verticalScrollReceivers[1];
		this.detectChanges();
	}

	ngOnDestroy() {
		this.destroyed$.next();
		this.destroyed$.complete();
		
		document.removeEventListener('mouseover', this.mouseover);
		document.removeEventListener('mouseout', this.mouseout);
	}
}
